@import "../../styles/variables";

.header {
  position: relative;
  z-index: 1000;
  .container {
    padding: 1.25rem 0;
  }
}

.nav {
  justify-content: space-between;
}

.header-nav {
  background-color: #000000;

  .twitter-icon {
    margin-left: 1rem !important;
  }

  .icon-img {
    min-width: 1.5rem;
  }

  .nav-item a {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #ffeed8;
    font-family: "Alverata";
    font-style: normal;
    text-decoration: none;
  }

  .locked .nav-item a {
    cursor: not-allowed;
    &:hover,
    &:focus {
      color: #959595;
    }
  }

  .lock {
    width: 15px;
    margin-left: 5px;
  }

  .nav-item a:focus {
    color: #d9a178;
  }

  .connect-wallet-btn {
    background: url("./connectwlbg@1.5x.png") center no-repeat;
    background-size: 100% 100%;
  }

  .nav-link,
  .nav-item,
  .nav {
    text-decoration: none !important;
  }

  .logo a {
    margin: 1.25rem 0;
  }

  .nav.active {
    .nav-link {
      color: #d9a178;
      position: relative;
      // &:after {
      //   content: "";
      //   opacity: 1;
      //   position: absolute;
      //   width: 3px;
      //   height: 3px;
      //   display: inline-block;
      //   background: url("./activedot@1.5x.png") center no-repeat;
      //   left: 0px;
      //   top: 16px;
      // }
    }
  }

  .nav-item a:hover {
    color: #efefef;
  }

  .nav-item-divider {
    color: #060606;
  }

  .wallet-btn {
    background-color: rgb(76, 42, 56) !important;
    height: 80%;
    font-size: 1rem;
    line-height: 1rem;
    font-family: "LifeCraft";
    font-style: normal;
    font-weight: bold;
    color: #ffffff;
    border: none;
    width: 9rem;
    height: 2.375rem;
  }

  .connect-button-wrapper {
    align-items: center;
  }

  .header-icons .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .menu-item-wrapper {
    display: flex;
    align-items: center;
  }

  .mobile-trigger {
    display: none;
  }

  @media screen and (max-width: 1199px) {
    justify-content: center;

    .connect-button-wrapper {
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 991px) {
    .connect-button-wrapper {
      margin-top: 0.5rem;
    }

    .logo a {
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 823px) {
    .logo {
      margin-right: 0 !important;
    }
  }

  @media screen and (max-width: $sm) {
    justify-content: space-between;
    padding-left: 1.25rem !important;
    padding: 0.3125rem 1.25rem;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-height;

    .logo {
      margin-bottom: 0;

      a {
        margin-top: 0.25rem;
      }
    }

    .connect-button-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 101;
      background-color: #0c0a0a;
      padding: 1rem;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    .mobile-trigger {
      display: block;
    }

    .menu-item-wrapper {
      padding-top: 2rem;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: $header-height;
      height: 100%;
      width: 100%;
      background-color: #0c0a0a;
      z-index: 100;
      transform: translateX(-100%);
      transition: all 0.3s ease-in-out;
      will-change: transform;

      .nav {
        margin-bottom: 1rem;
      }

      .header-icons {
        margin-top: 2rem;
      }
    }

    // mobile trigger
    .mobile-trigger {
      position: relative;
      display: inline-block;
      transition: all 0.3s ease-in-out;
      width: 30px;
      height: 30px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .open-menu {
        opacity: 1;
        width: 1.25rem;
      }

      .close-menu {
        width: 1rem;
        opacity: 0;
      }
    }

    // active state
    &.mobile-active {
      .mobile-trigger {
        transform: rotateZ(270deg);

        .open-menu {
          opacity: 0;
        }
        .close-menu {
          opacity: 1;
        }
      }
      .menu-item-wrapper {
        transform: translateX(0);
      }
    }
  }
}
