.elders-container {
  // background-image: linear-gradient(to top, #090607 0%, #0d0a0a 100%);
  height: 100%;
  .elders-container-first-row {
    margin-top: 5%;
  }
  .title {
    font-family: "Alverata";
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.1875rem;
    text-align: center;
    letter-spacing: 0.015em;
    color: #ffffff;
    margin-bottom: 3rem;
  }

  .elders-card-row {
    margin-top: 5%;
  }

  .elders-card {
    background-color: #0f0b0b;
    width: 110%;
    height: 82%;

    .card-header-image {
      margin-top: -27%;
    }
  }
}
