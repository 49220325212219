.map-page {
  .top {
    background-image: url("./background-1.png");
    // background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    padding-top: 56%;
    position: relative;
    z-index: 1;

    .bottom {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      height: 50%;
      width: 100%;
      z-index: 2;
    }
  }

  .map-container {
    margin-top: -56%;
    position: relative;
    z-index: 3;
    .img {
      text-align: center;
      margin: 18px 0 90px 0;
    }
  }


  // .map-container {
  //   h6 {
  //     color: #d9aea6;
  //     font-family: "Open Sans";
  //     font-size: 75%;
  //     font-weight: 400;
  //     font-style: normal;
  //     letter-spacing: normal;
  //     line-height: normal;
  //     text-align: center;
  //     text-transform: uppercase;
  //     text-align: center;
  //   }

  //   h4 {
  //     background-image: url("./brush.png");
  //     background-repeat: no-repeat;
  //     color: #f4ece3;
  //     font-family: "Life Craft";
  //     font-size: 250%;
  //     font-weight: 400;
  //     font-style: normal;
  //     letter-spacing: normal;
  //     line-height: normal;
  //     text-align: center;
  //     text-transform: uppercase;
  //   }

  //   p {
  //     color: #f6dfdb;
  //     font-family: "Open Sans";
  //     font-size: 80%;
  //     font-weight: 400;
  //     font-style: normal;
  //     letter-spacing: normal;
  //     line-height: 24px;
  //     text-align: center;
  //   }
  // }
}
