.loner-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    #140153 0%,
    #2a0589 25.52%,
    #8f47ac 48.96%,
    #d27aa3 76.56%,
    #faad99 100%
  );

  .container {
    margin-bottom: 4rem;

    .text {
      margin-top: 3.75rem;
      text-align: center;
      font-family: "Alverata";
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #FFFFFF;
    }
  }
}
