.main-page {
  font-family: "Alverata";

  .section-1 {
    video {
      width: 100%;
    }
  }

  .section-2 {
    background-image: url("./background/bg-section-1-1.png");
    background-size: 100%;
    background-position: top center;
    padding-top: 58%;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    margin-top: -0.375rem;
    display: flex;
    justify-content: center;

    .bg-right {
      background-image: url("./background/bg-section-1-2.png");
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 34%;
      height: 78%;
      background-size: 100%;
    }

    .content {
      z-index: 3;
      color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .title-section-2 {
        font-size: 3rem;
        line-height: 3.1875rem;
        text-align: center;
        letter-spacing: 0.015em;
        margin-bottom: 4rem;
      }

      .des-section-2 {
        font-weight: 400;
        font-size: 4rem;
        line-height: 4.375rem;
        text-align: center;
        letter-spacing: 0.015em;
      }
    }
  }

  .section-3 {
    margin-top: 10rem;
    position: relative;

    .content-section-3 {
      position: absolute;
      z-index: 3;

      .header-title {
        padding: 0 0 3rem 0;
      }

      .des {
        width: 28%;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.625rem;
        letter-spacing: -0.015em;
        color: #ffffff;
        text-align: left;
      }
    }

    .egg-empty {
      position: relative;
      .egg-left {
        filter: blur(7px);
        width: 28%;
        z-index: 1;
      }

      .egg-center {
        width: 28%;
        position: absolute;
        right: 36%;
        z-index: 2;
        margin-top: 4%;
      }

      .egg-right {
        right: 0;
        filter: blur(7px);
        width: 28%;
        z-index: 1;
      }

      .button-buy {
        position: absolute;
        width: 25%;
        right: 37.5%;
        top: 50%;
        z-index: 2;
      }
    }
  }

  .section-4 {
    color: #000000;
    margin-top: 10rem;

    .bg-section-4 {
      background-image: url("./background/bg-section-4.png");
      background-size: 100% 100%;
      background-position: top center;
      padding-top: 62%;
      position: relative;
      z-index: 1;
      background-repeat: no-repeat;

      .content-section-4 {
        position: absolute;
        top: 12%;
        width: 100%;

        .title-section-4 {
          font-weight: 400;
          font-size: 3rem;
          line-height: 3.1875rem;
          text-align: center;
          letter-spacing: 0.015em;
          margin-bottom: 3rem;
        }

        .des-section-4 {
          display: flex;

          .left {
            width: 50%;
            text-align: left;
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: -0.015em;
            padding: 0 0 0 15%;

            .des-bottom {
              font-weight: 400;
              font-size: 2.375rem;
              line-height: 2.8125rem;
              letter-spacing: 0.015em;
              margin-top: 1rem;
            }
          }

          .right {
            width: 50%;
            padding: 0 15% 0 2%;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .section-5 {
    background: linear-gradient(180deg, #000000 0%, #12090a 100%);
    margin-top: 2rem;

    .section-5-1 {
      font-weight: 400;
      font-size: 3rem;
      line-height: 3.1875rem;
      text-align: center;
      letter-spacing: 0.015em;
      color: #ffffff;

      .title-section-5 {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
    .section-5-2 {
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #12090a 100%);
      margin-top: 7rem;
    }
  }

  .section-6 {
    background-image: url("./background/bg.png");
    background-size: 100%;
    background-position: top center;
    padding-top: 60%;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    margin-top: -8rem;

    .bg-section-6 {
      position: absolute;
      top: 0;
      height: 50%;
      width: 100%;
      z-index: 2;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #12090a 100%);
      transform: rotate(-180deg);
    }
  }

  @media screen and (max-width: 1640px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 67%;
      }
    }
  }

  @media screen and (max-width: 1540px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 74%;
      }
    }
  }

  @media screen and (max-width: 1399px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 86%;
      }
    }
  }

  @media screen and (max-width: 1299px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 95%;
      }
    }
  }

  @media screen and (max-width: 1249px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 104%;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 85%;
        .content-section-4 {
          top: 10%;

          .title-section-4 {
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.125rem;
            margin-bottom: 2rem;
          }

          .des-section-4 {
            .left {
              font-size: 0.875rem;
              line-height: 1.125rem;

              .des-bottom {
                font-size: 1.75rem;
                line-height: 2rem;
                margin-top: 1rem;
              }
            }

            .right {
              img {
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1099px) {
    .section-4 {
      .bg-section-4 {
        padding-top: 102%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .section-2 {
      .content {
        .title-section-2 {
          margin-bottom: 2.5rem;
          font-size: 2rem;
          line-height: 2.125rem;
        }

        .des-section-2 {
          font-size: 2.625rem;
          line-height: 2.9125rem;
        }
      }
    }

    .section-3 {
      .content-section-3 {
        .header-title {
          padding: 0 0 2rem 0;
        }
        .des {
          width: 32%;
        }
      }
    }

    .section-4 {
      .bg-section-4 {
        padding-top: 128%;
      }
    }

    .section-5 {
      margin-top: 3rem;
      .promise-container {
        background-image: unset;
      }
      
      .section-5-1 {
        .title-section-5 {
          font-size: 2.25rem;
          line-height: 2.375rem;
        }
      }

      .section-5-2 {
        .title {
          font-size: 2.25rem;
          line-height: 2.375rem;
        }

        .elder-card-container {
          img {
            max-width: 10.5rem;
            max-height: 4rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .section-2 {
      .content {
        .title-section-2 {
          margin-bottom: 1rem;
          font-size: 1.25rem;
          line-height: 1.375rem;
        }

        .des-section-2 {
          font-size: 1.5rem;
          line-height: 1.625rem;
        }
      }
    }

    .section-3 {
      margin-top: 4rem;
      .content-section-3 {
        .header-title {
        }
        .des {
          width: calc(100% - 1.25rem);
        }
      }

      .egg-empty {
        padding-top: 15rem;

        .egg-left {
          width: 40%;
        }

        .egg-center {
          width: 40%;
          right: 30%;
        }

        .egg-right {
          width: 40%;
        }

        .button-buy {
          top: 76%;
          width: 35%;
          right: 32.5%;
        }
      }
    }

    .section-4 {
      margin-top: 4rem;
      .bg-section-4 {
        padding-top: 275%;
        background-size: 103% 100%;

        .content-section-4 {
          top: 50%;
          transform: translateY(-50%);

          .title-section-4 {
            font-size: 1.25rem;
            line-height: 1.375rem;
          }

          .des-section-4 {
            flex-direction: column;

            .left {
              width: 85%;
              font-size: 0.75rem;
              line-height: 0.875rem;

              .des-bottom {
                font-size: 1.25rem;
                line-height: 1.375rem;
              }
            }

            .right {
              width: 100%;
              padding: 2rem 15% 0 15%;
            }
          }
        }
      }
    }

    .section-5 {
      .section-5-1 {
        .title-section-5 {
          font-size: 1.75rem;
          line-height: 2rem;
        }
      }

      .section-5-2 {
        margin-top: 3rem;
        .title {
          font-size: 1.75rem;
          line-height: 2rem;
        }

        .elder-card-container {
          img {
            max-width: 10.5rem;
            max-height: 4rem;
          }
        }
      }
    }
  }
}
// .main-image-container {
//   background-color: #0b080a;
// }

// .intro-container {
//   background-color: #010101;
//   background-image: url("./main-image-background.svg");
// }

// .Main {
//   h4 {
//     color: #f4ece3;
//     font-family: "Life Craft";
//     font-size: 60px;
//     font-weight: 400;
//     font-style: normal;
//     letter-spacing: normal;
//     line-height: normal;
//     text-align: center;
//     text-transform: uppercase;
//     /* Text style for "I, NTRO HE" */
//     font-style: normal;
//     letter-spacing: 6px;
//     line-height: normal;
//   }

//   //.egg-container {
//   //    margin-top: -157px !important;
//   //}

//   .btns-area {
//     font-family: "Myriad Pro", serif;
//     > span.btn-icon {
//       width: 21px;
//       height: 21px;
//       cursor: pointer;
//       margin-left: 10px;
//       margin-right: 10px;
//       background: url("./assets/minus_normal.png") center no-repeat;

//       &.plus-icon {
//         background-image: url("./assets/add_normal@1.5x.png");
//         background-size: contain;
//       }
//     }
//     span {
//       font-family: "Myriad Pro", serif;
//       font-size: 20px;
//       font-weight: 700;
//       color: #ffffff;
//       line-height: 24px;
//     }
//   }
// }
// .intro-image {
//   img {
//     //width: 111%;
//     //margin-left: -5.5%;
//   }

//   p {
//     /* Style for "In an anci" */
//     width: 75%;
//     color: #f6dfdb;
//     font-family: "Open Sans";
//     font-size: 75%;
//     font-weight: 400;
//     font-style: normal;
//     letter-spacing: normal;
//     line-height: 20px;
//     text-align: center;
//     position: relative;
//     top: -47%;
//     left: 12.5%;
//   }
// }

// .egg-container {
//   h6 {
//     color: #d9aea6;
//     font-family: "Open Sans";
//     font-size: 75%;
//     font-weight: 400;
//     font-style: normal;
//     letter-spacing: normal;
//     line-height: normal;
//     text-align: center;
//     text-transform: uppercase;
//     text-align: center;
//   }

//   h4 {
//     color: #f4ece3;
//     font-family: "Life Craft", serif;
//     font-size: 250%;
//     font-weight: 400;
//     font-style: normal;
//     letter-spacing: normal;
//     line-height: normal;
//     text-align: center;
//     text-transform: uppercase;
//   }

//   p {
//     color: #f6dfdb;
//     font-family: "Open Sans", serif;
//     font-size: 80%;
//     font-weight: 400;
//     font-style: normal;
//     letter-spacing: normal;
//     line-height: 24px;
//     text-align: center;
//   }

//   .dragon-egg {
//     margin-top: 5%;
//     p {
//       color: #ffffff;
//       font-family: "Open Sans";
//       font-size: 13px;
//       font-weight: 400;
//       font-style: normal;
//       letter-spacing: normal;
//       line-height: 24px;
//       text-align: center;
//     }
//   }

//   .dragon-egg-image {
//     width: 20%;
//     min-width: 180px;
//   }

//   .wonderquest-mainImage {
//     max-width: 100%;
//   }

//   //.gradient-main-text {
//   //  background: -webkit-linear-gradient(#d9aea6, white, #d9aea6);
//   //  -webkit-background-clip: text;
//   //  -webkit-text-fill-color: transparent;
//   //}
//   .mint-egg-btn {
//     border-radius: 0% !important;
//     width: 417px;
//     max-width: 90%;
//     outline: none !important;
//     border: none !important;
//     box-shadow: none !important;

//     background: url("./assets/Button_Normal@1.5x.png") center no-repeat;
//     background-size: contain;

//     padding-bottom: 20px;

//     color: #ffffff;
//     font-family: "Life Craft", serif;
//     font-size: 33px;
//     line-height: 1.9;
//     text-shadow: 0 0 3px #141415;
//     margin-top: 0.5rem !important;
//     span {
//       position: relative;
//       top: 13px;
//     }
//   }

//   .mint-egg-btn:hover {
//     background-color: transparent;
//     border-radius: 0% !important;
//     border: none !important;
//     margin-top: -5%;
//   }

// }

// .link-text {
//   text-decoration: none !important;
//   color: #f6dfdb !important;
//   &:hover {
//     opacity: 0.9;
//   }
// }
