/* @font-face {
  font-family: "Life Craft";
  src: local("Life Craft"), url("./fonts/LifeCraft_Font.ttf") format("truetype");
}

@font-face {
  font-family: "Myriad Pro";
  src: local("Myriad Pro"), url("./fonts/MYRIADPRO-REGULAR.woff") format("woff");
} */

@font-face {
  font-family: "Alverata";
  src: local("Alverata"), url("./fonts/Alverata.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Alverata";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-icons > div.discord-icon {
  margin-left: 0;
  padding: 0;
}
.header-icons > div.discord-icon > .nav-link {
  padding: 0;
}
.header-icons > div.twitter-icon {
  margin-left: 0;
  padding: 0;
}
.header-icons > div.twitter-icon > .nav-link {
  padding: 0;
}

#intro-cage-wrap {
  background: url("./components/home/intro-container-background.png") 50% 50%
    no-repeat;
  background-size: 100%;
  padding: 100px 0;
}

.intro-cage {
  color: #f6dfdb;
  font-family: "Open Sans";
  font-size: 80%;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}

#intro-cage-inner {
  max-width: 800px;
  margin: 0 auto;
}

.intro-cage-second {
  padding-top: 8px;
}

.elder-cards {
  clear: both;
  padding-top: 30px;
  margin: 0 auto;
  max-width: 1100px;
  z-index: 3;
}

.elder-card {
  float: left;
  width: 25%;
  display: block;
  height: 8rem;
  box-sizing: border-box;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#elders-intro {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.elder-card-container > img {
  max-height: 5rem;
  max-width: 12.5rem;
  display: block;
  margin: 0 auto 30px auto;
}

.elder-card-last-row {
  margin-left: 16.6%;
}

#breed-intro {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#center-footer {
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-l.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 1280px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-ml.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 880px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-m.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
  .intro-cage {
    padding-top: 0;
  }
}

@media screen and (max-width: 780px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-ms.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
  .intro-cage {
    padding-top: 0;
  }
}

@media screen and (max-width: 650px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
  .intro-cage {
    padding-top: 0;
  }
  #intro-cage-inner {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 550px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s2.png") 50%
      50% no-repeat;
    background-size: 100%;
    padding: 50px 0;
  }
}

@media screen and (max-width: 500px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s3.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 450px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s5.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 380px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s6.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 350px) {
  #intro-cage-wrap {
    background: url("./components/home/intro-container-background-s7.png") 50%
      50% no-repeat;
    background-size: 100%;
  }
}

@media screen and (max-width: 880px) {
  .elder-card {
    /* height: 320px; */
  }
}

@media screen and (max-width: 800px) {
  .elder-card {
    width: 50%;
  }
  .elder-card-last-row {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .elder-card {
    width: 100%;
    height: auto;
  }
  .elder-card-last-row {
    margin-left: 0;
  }
}

.container {
  width: 80%;
  /* max-width: 1300px; */
  padding: 0 !important;
  margin: 0 auto;
}


@media screen and (min-width: 992px) {
  .container {
    max-width: 80% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .container {
    max-width: 92% !important;
  }
}

/* @media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
} */

@media screen and (max-width: 992px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 1.25rem !important;
  }
}

@media screen and (min-width: 1921px) {
  .container {
    max-width: 1920px;
  }
}
