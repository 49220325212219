.update-page {
  font-family: "Alverata";
  .top {
    background-image: url("./background-update.png");
    background-size: 100%;
    background-position: top center;
    padding-top: 36%;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;

    .bottom {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      background-repeat: no-repeat;
      position: absolute;
      top: 20%;
      height: 80%;
      width: 100%;
      z-index: 2;
    }
  }

  .update-container {
    margin-top: -36%;
    position: relative;
    z-index: 3;

    .roadmap {
      margin-top: 5rem;
      margin-bottom: 5rem;
      display: flex;
      font-style: normal;
      font-weight: 400;
      text-align: right;
      letter-spacing: 0.015em;
      color: #ffeed8;
      position: relative;

      .img-top {
        position: absolute;
        left: calc(50% - 18px);
      }

      .img-bottom {
        position: absolute;
        left: calc(50% - 18px);
        bottom: -1rem;
      }

      .text {
        font-size: 1.875rem;
        line-height: 2.125rem;
      }

      .line-1 {
        min-width: 30%;
        max-width: 30%;
        height: 1px;
        background: linear-gradient(180deg, #ffeed8 100%, #6e3547 100%);
        margin-left: 1rem;
      }

      .line-2 {
        min-width: 30%;
        max-width: 30%;
        height: 1px;
        background: #6e3547;
        margin-left: 1rem;
      }

      .rm-left {
        width: 50%;
        border-right: 1px solid;
        -webkit-border-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#ffeed8),
            to(#6e3547)
          )
          0 100% 0 0 stretch;

        .item-left {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 18rem;
        }

        .left-1 {
          margin-top: -4rem;
        }
      }

      .rm-right {
        width: 50%;
        margin-top: 5rem;
        margin-bottom: -5rem;

        .item-right {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 18rem;

          .line-1,
          .line-2 {
            margin-left: 0;
            margin-right: 1rem;
          }

          .text {
            text-align: left;
          }
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    .top {
      background-size: cover;
      padding-top: 80%;
    }
    .update-container {
      margin-top: -80%;
    }
  }

  @media screen and (max-width: 640px) {
    .update-container {
      .roadmap {
        margin-top: 1rem;
        .text {
          font-size: 1rem;
          line-height: 1.25rem;
        }

        .line-1 {
          margin-left: 0.5rem;
        }

        .line-2 {
          margin-left: 0.5rem;
        }

        .rm-right {
  
          .item-right {
            .line-1,
            .line-2 {
              margin-left: 0;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
