@import "./variables";
@import "./common.scss";

* {
  scroll-behavior: smooth;
}

@media screen and (max-width: $sm) {
  .app-content {
    margin-top: $header-height;
  }
}

.Toastify {
  text-align: left;
}
