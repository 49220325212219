.whitepaper-main-div {
    background-color: #050404;
    color: #f6dfdb;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;

    p {
        margin-bottom: 2rem;
    }

    .container {
        max-width: 100%;

        @media screen and (min-width: 576px) {
            max-width: 540px;
        }

        @media screen and (min-width: 768px) {
            max-width: 720px;
        }

        @media screen and (min-width: 992px) {
            max-width: 960px;
        }

        @media screen and (min-width: 992px) {
            max-width: 960px;
        }

        @media screen and (min-width: 1200px) {
            max-width: 1140px;
        }

        @media screen and (min-width: 1366px) {
            max-width: 1320px;
        }

        @media screen and (min-width: 1600px) {
            max-width: 1571px;
        }
        
    }

    .section {
        padding: 100px 0;
        border-top: 2px solid #131211;
        border-bottom: 2px solid #131211;

        &:first-child {
            border-top: none;
        }

        &:last-child {
            border-bottom: none;
        }

        &__text-image,
        &__heading-copy,
        &__project-team {
            text-align: left;

            &.text-left {
                text-align: left;

                .box-heading {
                    &:after {
                        left: 0;
                        transform: none;
                    }
                }
            }

            .box-heading {
                margin-bottom: 35px;

                @media screen and (max-width: 991px) {
                    margin-top: 50px;
                }
            }

            .box-content {
                font-size: 28px;
                line-height: 38px;
                padding: 35px 0;
                border-top: 1px solid #1c1716;

                ul {
                    font-size: 18px;
                    line-height: 28px;
                    padding-left: 17px;
                }
            }

            .box-image {
                margin-top: 25px;

                img {
                    width: 255px;
                    height: auto;
                    object-fit: contain;
                }
            }

            .main-image {
                padding-left: 30px;

                @media screen and (max-width: 991px) {
                    padding-left: 0;
                }
            }
        }

        &__text-image {
            .row {
                @media screen and (max-width: 991px) {
                    flex-direction: column-reverse;
                }
            }
        }

        &__heading-copy,
        &__project-team {
            text-align: center;

            .box-heading {
                position: relative;
                
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #1c1716;
                    width: 600px;
                    height: 1px;
                    z-index: 1;
                    max-width: 100%;
                }
            }

            .box-content {
                border-top: none;

                .text-38 {
                    font-size: 38px;
                    line-height: 48px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .fs-normal {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .box-content-grid {
                font-size: 28px;
                line-height: 38px;
                padding: 35px 0;

                b {
                    font-weight: bold;
                }

                .color-white {
                    color: #fff;
                }

                .grid-item {
                    text-align: left;
                    margin: 10px 0;
                    ul {
                        font-size: 18px;
                        line-height: 28px;
                        padding-left: 17px;
                        padding-right: 75px;

                        @media screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        

        &__project-team {
            .wrap-project-teams {
                .project-team {
                    flex: 0 calc(100% / 5);
                    max-width: calc(100% / 5);
                    padding-right: calc(var(--bs-gutter-x) * -.5);
                    padding-left: calc(var(--bs-gutter-x) * -.5);
                    margin: 10px 0;

                    @media screen and (max-width: 1365px) {
                        flex: 0 calc(100% / 3);
                        max-width: calc(100% / 3);
                    }

                    @media screen and (max-width: 991px) {
                        flex: 0 50%;
                        max-width: 50%;
                    }

                    @media screen and (max-width: 575px) {
                        flex: 0 100%;
                        max-width: 100%;
                    }

                    .box-item {
                        padding: 0 35px;
                        @media screen and (max-width: 575px) {
                            padding: 0;
                        }

                        .box-logo {
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 170px;
                            margin-bottom: 25px;

                            img {
                                margin-left: auto;
                                margin-right: auto;
                                object-fit: contain;
                            }
                        }
                        
                        .title {
                            width: 100%;
                            margin-bottom: 30px;
                            font-size: 28px;
                            line-height: 38px;
                        }
                    }
                }
            }
        }
    }

    .sub-heading {
        color: #d9aea6;
        font-family: "Open Sans";
        font-size: 28px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-transform: uppercase;
    }

    .heading {
        color: #f4ece3;
        font-family: "Life Craft", serif;
        font-size: 60px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 3px;
        line-height: normal;
        text-transform: uppercase;
    }

    .main-heading-container {
        margin-top: 40px;
    }
}
