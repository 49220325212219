.header-title {
  padding: 7.125rem 0 4rem 0;
  margin-bottom: 0;
  font-size: 3rem;
  line-height: 2.1875rem;
  color: #FFFFFF;
  font-family: 'Alverata';
  // font-style: 'Regular';
  text-align: left;
}

@media screen and ( max-width: 820px ) {
  .header-title {
    padding: 3.5rem 0;
  }
}

@media screen and ( max-width: 640px ) {
  .header-title {
    font-size: 1.875rem;
  }
}