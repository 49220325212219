.elder-card {
    // background-image: linear-gradient(-3deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 100%);
    span {
        font-size: 30px;
        color: #b79377;
        margin-top: -2%;
    }
    p {
        text-align: center;
    }
}
