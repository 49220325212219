.promise-map-card {
    position: relative;
    //background-image: url('./promise-map-card.png');
    //padding: 2%;
    //.map-card-header-image {
    //    margin-top: -27%;
    //}


    .item-image {
        width: 231px;
        height: 189px;
        display: inline-block;
        background: url('./item1.png') bottom center no-repeat;

        &.image-2 {
            background-image: url('./item2.png');
        }

        &.image-5 {
            background-image: url('./item3.png');
        }
    }

    .lock {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
