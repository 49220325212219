@import "../home/home";
.inventory-page {
  .top {
    background-image: url("./assets/background.png");
    background-size: 100%;
    background-position: top center;
    padding-top: 56%;
    position: relative;
    z-index: 1;

    .bottom {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      background-repeat: no-repeat;
      position: absolute;
      top: 20%;
      height: 80%;
      width: 100%;
      z-index: 2;
    }
  }
  .des {
    font-family: "Alverata";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.625rem;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-align: left;
    width: 33%;
  }

  .inventory-container {
    margin-top: -56%;
    position: relative;
    z-index: 3;
  }
  .img {
    text-align: center;
    position: relative;
    z-index: 3;
    margin-bottom: 6rem;
    // margin-top: 3rem;

    .img-egg {
      img {
        width: 20%;
      }
    }
    .img-button {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      img {
        width: 17.5%;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .img {
      margin-top: -5rem;
    }
  }

  @media screen and (max-width: 820px) {
    .top {
      background-size: cover;
      padding-top: 80%;
    }
    .inventory-container {
      margin-top: -80%;
    }
    .des {
      margin-top: -1rem;
    }
    .img {
      margin-top: -3rem;

      .img-egg {
        img {
          width: 33%;
        }
      }

      .img-button {
        img {
          width: 28.5%;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .inventory-container {
      .des {
        width: 100%;
        margin-bottom: 5rem;
      }
    }
  }

  h6 {
    color: #d9aea6 !important;
    font-family: "Open Sans", serif;
    font-size: 14px !important;
    font-weight: 400;
    font-style: normal;
  }
  p,
  .normal-text {
    color: #f6dfdb;
    font-family: "Open Sans", serif;
    font-size: 13px;
    font-weight: 400;
  }

  .main-btn-area {
    margin-top: -20px;
  }

  .egg-icon {
    margin: 10px;
    cursor: pointer;
    div.border-cover {
      display: none;
    }
    &:hover,
    &.active {
      div.border-cover {
        display: block !important;
        background: url("./assets/egg_border.png") center no-repeat;
        top: -4px;
        right: -4px;
        left: -4px;
        bottom: -4px;
      }
    }
  }
}
