@import "../../styles/variables";

.promise-container {
  background-image: url("./background-world.png");
  background-size: 100% 100%;
  background-position: top center;
  // padding-top: 62%;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  // background: linear-gradient(180deg, #000000 0%, #12090A 100%);;
  height: 100%;
  padding-bottom: 2%;
  .promise-container-first-row {
    margin-top: 130px;
  }
  h6 {
    color: #d9aea6;
    font-family: "Open Sans", serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    /* Style for "THE PROMIS" */
    color: #f4ece3;
    font-family: "Life Craft";
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    color: #f6dfdb;
    font-family: "Open Sans", serif;
    font-size: 86%;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
  }
  .promise-card-row {
    margin-top: 5%;
    padding-bottom: 2%;
  }

  .promise-card {
    height: 100% !important;
    padding-bottom: 0;
    padding-bottom: 20px;
    background: #0f0b0b url("./deco@1.5x.png") no-repeat bottom center;
    background-size: contain;
    margin-bottom: 20px;

    max-width: 340px;

    .card-header-image {
      //margin-top: -27%;
    }

    .card-heading {
      margin-top: 5%;
      color: #ffffff;
      font-family: "Open Sans", serif;
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 28px;
      text-align: center;
      text-transform: uppercase;
    }

    .card-description {
      color: #ffffff;
      font-family: "Myriad Pro", serif;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      padding-left: 55px;
      padding-right: 55px;
      margin-bottom: 0;
    }

    .bottom-image {
      margin-top: -50%;
      margin-left: -11%;
      width: 122%;
    }
  }

  .map-container {
    // padding: 0 15%;
    margin-bottom: 4%;
    margin-top: 7%;
    justify-content: center;
    gap: 1.25rem;

    .line {
      height: 100%;
      width: 1px;
      background-color: red;
    }
  }

  @media screen and (max-width: $lg) {
    .card-container {
      flex-wrap: wrap !important;
    }

    .map-container {
      flex-wrap: wrap !important;
    }

    .promise-cart-item {
      flex-wrap: wrap;
      width: 50%;
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: $md) {
    .map-container {
      justify-content: center !important;
    }

    // .promise-map-card {
    //   margin: 0 1rem 0.5rem;
    // }
  }

  @media screen and (max-width: $sm) {
    .promise-container-first-row {
      margin-top: 5rem;
    }

    .promise-cart-item {
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
