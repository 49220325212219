@import "../../styles/variables";

.footer {
  font-family: "Alverata";
  .footer-container {
    background: #000000;
    padding-bottom: 3rem;

    .contract {
      font-style: normal;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 1.625rem;
      text-align: center;
      letter-spacing: -0.015em;
      color: #ffffff;
      padding: 3rem 0;
    }

    .footer-container-row {
      padding-top: 3rem;

      .mailchip {
        width: 33.3%;
        display: flex;
        align-items: center;

        .custom-form-content {
          width: 100%;
          min-width: 300px;

          .input-group {
            button {
              background-color: #48242f;
              border-radius: unset;
              border: none;
              padding: 0 1.25rem;
            }

            button:hover {
              background-color: #b79377;
              border: none !important;
            }

            input {
              border: none;
              font-size: 1rem;
              background: #d9d9d9;
              border-radius: unset;
              color: #000000;

              &:focus {
                box-shadow: none !important;
              }
            }
          }
        }
      }
      .logo {
        width: 33.3%;

        img {
          max-width: 250px;
        }
      }
      .social {
        width: 33.3%;
        align-items: center;
        justify-content: flex-end;

        .twitter-icon {
          margin-left: 1.25rem;
        }
      }
    }

    .mailchip-mess {
      font-size: 0.75rem;
      text-align: left;
      display: block;
      padding-left: 6.5rem;
      margin-top: 0.5rem;

      &.success {
        color: #31ca17;
      }

      &.error {
        color: red;
      }

      &.sending {
        color: #f0bda1;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .footer-container {
      .footer-container-row {
        display: flex;
        flex-direction: column;
        align-items: center;

        .mailchip {
          width: 100%;
          order: 3;

          .custom-form-content {
            text-align: -webkit-center;

            .input-group {
              max-width: 500px;
            }
          }
        }
        .logo {
          width: 100%;
          order: 1;
        }
        .social {
          width: 100%;
          order: 2;
          margin: 2rem 0;
          justify-content: space-evenly;
          width: 15rem;
        }
      }
    }
  }

  @media screen and (max-width: $sm) {
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 640px) {
    .footer-container {
      .contract {
        font-size: 0.9375rem;
        line-height: 1.375rem;
      }
    }
  }
}
