.breeds-page {
  font-family: "Alverata";
  .top {
    background-image: url("./background-breeds.png");
    background-size: 100%;
    background-position: top center;
    padding-top: 36%;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;

    .bottom {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      background-repeat: no-repeat;
      position: absolute;
      top: 20%;
      height: 80%;
      width: 100%;
      z-index: 2;
    }
  }

  .breeds-container {
    margin-top: -36%;
    position: relative;
    z-index: 3;

    .banner-top {
      display: flex;

      .des {
        font-style: normal;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.625rem;
        letter-spacing: -0.015em;
        color: #ffffff;
        text-align: left;
        width: 33%;
      }

      .egg-empty {
        margin-top: -6rem;
        .egg-left {
          filter: blur(7px);
          width: 25%;
          position: absolute;
          right: 25%;
          z-index: 1;
        }

        .egg-center {
          width: 25%;
          position: absolute;
          right: 12.5%;
          z-index: 2;
          margin-top: 1.875rem;
        }

        .egg-right {
          right: 0;
          filter: blur(7px);
          width: 25%;
          position: absolute;
          z-index: 1;
        }
      }
    }

    .item-select {
      // display: flex;
      align-items: center;
      position: relative;
      .infor {
        margin-top: 5rem;
        background-image: url("./scroll.png");
        background-size: 104%;
        background-position: top center;
        padding-top: 90%;
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        width: 62%;

        .data {
          width: 60%;
          position: absolute;
          top: 15%;
          left: 5rem;
          text-align: left;

          .index {
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.25rem;
            letter-spacing: 0.015em;
            color: #ffeed8;
          }

          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 5rem;
            line-height: 5rem;
            letter-spacing: 0.015em;
            color: #000000;
            margin-top: 1rem;
          }

          .note {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            font-style: normal;
            font-weight: 700;
            font-size: 2rem;
            line-height: 3rem;
            letter-spacing: 0.015em;
            color: #000000;
            border-bottom: 1px solid #000000;
            display: inline-flex;
          }

          .weight-rarity {
            font-size: 2rem;
            line-height: 2.25rem;
            letter-spacing: 0.015em;
            color: #000000;
            display: flex;

            .text {
              min-width: 9rem;
            }
          }

          .des-egg {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: -0.015em;
            color: #000000;
            margin-top: 1.5rem;
          }
        }
      }

      img {
        width: 53%;
        position: absolute;
        top: 20%;
        right: 0;
        z-index: 2;
        border-radius: 2rem;
      }
    }

    .text-select-egg {
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      line-height: 3.25rem;
      letter-spacing: 0.015em;
      color: #ffffff;
      text-align: left;
      margin: 2rem 0 4rem 0;
    }

    .list-eggs {
      margin-bottom: 3rem;
      .ant-row {
        display: flex;
        flex-flow: row wrap;

        img {
          margin: 0.75rem 0;
          border-radius: 2rem;
          cursor: pointer;
        }
      }
    }
  }

  // @media screen and (min-width: 1399px) {
  //   .breeds-container {
  //     .header-title {
  //       margin-left: 2rem;
  //     }
  //     .banner-top {
  //       margin-left: 2rem;
  //     }
  //   }

  // }

  @media screen and (max-width: 1599px) {
    .breeds-container {
      margin-top: -36%;

      .item-select {
        .infor {
          background-size: 100% 100%;
          padding-top: 115%;
        }

        img {
          top: 27%;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .breeds-container {
      .item-select {
        .infor {
          padding-top: 125%;
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .breeds-container {
      .item-select {
        .infor {
          padding-top: 140%;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .breeds-container {
      .item-select {
        .infor {
          width: 73%;
          .data {
            top: 14%;
            left: 4rem;

            .index {
              font-size: 1.75rem;
              line-height: 2rem;
            }

            .name {
              font-size: 3.25rem;
              line-height: 3.5rem;
            }

            .note {
              font-size: 1.75rem;
              line-height: 2.5rem;
            }

            .weight-rarity {
              font-size: 1.75rem;
              line-height: 2rem;
              .text {
              }
            }

            .des-egg {
              line-height: 1.25rem;
            }
          }
        }
        img {
          width: 47%;
          top: 30%;
        }
      }
    }
  }

  @media screen and (max-width: 1099px) {
    .breeds-container {
      .item-select {
        .infor {
          padding-top: 150%;
        }

        img {
          // top: 27%;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .breeds-container {
      .banner-top {
        .des {
          width: 45%;
        }
      }

      .item-select {
        .infor {
          padding-top: 125%;
          width: 78%;
          .data {
            width: 63%;
            left: 3rem;
            .index {
              font-size: 1.5rem;
              line-height: 1.75rem;
            }

            .name {
              font-size: 2.5rem;
              line-height: 2.75rem;
            }

            .note {
              font-size: 1.5rem;
              line-height: 1.75rem;
              margin-top: 1rem;
              margin-bottom: 0.5rem;
            }

            .weight-rarity {
              font-size: 1.5rem;
              line-height: 1.75rem;
              .text {
                min-width: 6rem;
              }
            }

            .des-egg {
              margin-top: 1rem;
              font-size: 0.875rem;
              line-height: 1rem;
            }
          }
        }

        img {
          width: 42%;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .breeds-container {
      .banner-top {
        .des {
          width: 100%;
          z-index: 4;
        }

        .egg-empty {
          .egg-left {
            width: 30%;
            right: 30%;
          }

          .egg-center {
            width: 30%;
            right: 15%;
          }

          .egg-right {
            width: 30%;
          }
        }
      }

      .item-select {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 3rem;
        .infor {
          width: 100%;
          padding-top: 250%;
          background-size: 106% 100%;
          margin-top: 2.5rem;
          text-align: center;
          .data {
            left: 9%;
            width: 83%;
          }
        }

        img {
          width: 100%;
          max-width: 400px;
          position: unset;
        }
      }

      .text-select-egg {
        font-size: 2rem;
        line-height: 2.25rem;
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .breeds-container {
      .item-select {
        .infor {
          padding-top: 280%;
          .data {
            left: 8%;
            width: 84%;
          }
        }
      }
    }
  }
}
